<template>

    <b-card style="height: fit-content" no-body>

        <!--Header-->
        <h5 slot="header" style="margin-bottom: 0;">
            <b>
                Profile Overview -
                <span v-if="local.firstname && local.lastname">{{local.firstname}} {{local.lastname}}</span>
                <span v-else>{{local.username}}</span>
            </b>
        </h5>

        <!--Body-->
        <b-row style="margin: 3px">

            <!--Info/Nav-Tabs-->
            <b-col md="4" class="d-flex flex-column w-100" style="padding: 0">

                <!--Profile Info-->
                <b-card bg-variant="dark" style="margin: 3px">
                    <b slot="header" style="margin-bottom: 0">Profile Info</b>
                    <span style="font-size:15px"><b>Name:</b> {{local.firstname}} {{local.lastname}} <br>
              <b>Username:</b> {{local.username}} <br>
              <b>Email:</b> {{local.email}} <br>
              <b>User Type:</b> {{local.type}}
            </span>
                </b-card>

                <!--Nav-Tab Order-->
                <b-card bg-variant="dark" style="margin: 3px; height: 838px; overflow-y: hidden" no-body>
                    <b slot="header" style="margin-bottom: 0;">Nav-Tab Order</b>
                    <div style="overflow-y: auto            ">
                        <vue-nestable v-model="navOptions" :max-depth="1" childrenProp="false" @change="updateNav" style="margin: 6px">
                            <vue-nestable-handle slot-scope="{ item }" :item="item">
                                <b-badge style="width: 100%; text-align: left !important; background-color: #090909" >
                                    <span class="d-flex justify-content-between align-items-center">
                                        <span class="d-flex align-items-center">
                                            <i style="cursor: auto; margin-right: 10px" :class="item.icon + ' icons font-lg'"></i>
                                            <span>{{ item.name }}</span>
                                        </span>
                                        <fa-icon :icon="['fas', 'sort']"/>
                                    </span>
                                </b-badge>
                            </vue-nestable-handle>
                        </vue-nestable>
                    </div>
                </b-card>

            </b-col>

            <!--Tabs-->
            <b-col md="8" class="d-flex flex-column" style="padding: 0">
                <b-card bg-variant="dark" no-body style="margin: 3px; border-color: #000001; background-color: #0e0e0e !important; height: 1020px">
                    <b-tabs fluid class="d-flex flex-column" varient="dark" style="height: 1019px">

                        <!--Groups Tab-->
                        <b-tab class="active tab-scroll" title="Your Groups">
                            <b-card v-for="(group,index) in groups" :key="index" no-body class="mb-1" style="margin: 0 10px 0 10px">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button class="p-0" block href="#" v-b-toggle="'group-'+ index" variant="black" style="margin: 0 10px; line-height: 15px">
                                        <b>{{group.groupname}} Group</b>
                                    </b-button>
                                </b-card-header>
                                <b-collapse :id="'group-' + index" visible accordion="my-accordion2" role="tabpanel">
                                    <b-card-body>
                                        <b>Associated Constellations:</b> <br> <span v-for="(name,index) in group.constellationnames" :key="index">{{name}}<span v-if="index!==group.constellationnames.length-1">, <br></span></span>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-tab>

                        <!--Constellations Tab-->
                        <b-tab class="tab-scroll" title="Your Constellations">
                            <b-card v-for="(constellation,index) in constellationList" :key="index" no-body class="mb-1" style="margin: 0 10px 0 10px">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button class="p-0" block href="#" v-b-toggle="'accordion-'+ index" variant="black">{{constellation.constellationname}}</b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-row>
                                            <b-col sm="6" lg="3">
                                                <b>Constellation:</b> {{constellation.constellationname}}
                                                <br>
                                                <b>Address:</b> {{constellation.url}}
                                                <br>
                                                <b>Total Devices:</b> {{constellation.numActiveDevices + constellation.numInactiveDevices}}
                                                <br>
                                                <b>Active Devices:</b> {{constellation.numActiveDevices}}
                                                <br>
                                                <b>Inactive Devices:</b> {{constellation.numInactiveDevices}}
                                            </b-col>
                                            <b-col sm="6" lg="3">
                                                <h4>Storage:
                                                    <small class="text-muted">234/500TB</small>
                                                </h4>
                                                <b-progress height="20px" :value="constellation.storage" variant="default"
                                                            show-progress class="mb-2"></b-progress>

                                                <h4>CPU:
                                                    <small class="text-muted">22 load avg</small>
                                                </h4>
                                                <b-progress height="20px" :value="constellation.cpu" variant="default" show-progress
                                                            class="mb-2"></b-progress>
                                            </b-col>
                                            <b-col sm="6" lg="3">
                                                <h4>RAM:
                                                    <small class="text-muted">33/64GB</small>
                                                </h4>
                                                <b-progress height="20px" :value="constellation.ram" variant="warning" show-progress
                                                            class="mb-2"></b-progress>
                                                <h4>I/O:
                                                    <small class="text-muted">234/500 Iops</small>
                                                </h4>
                                                <b-progress height="20px" :value="constellation.io" variant="Default" show-progress
                                                            class="mb-2"></b-progress>
                                            </b-col>
                                            <b-col sm="6" lg="3">
                                                <h4>NIC-1:
                                                    <small class="text-muted">7/10GB</small>
                                                </h4>
                                                <b-progress height="20px" :value="constellation.nic1" show-progress
                                                            class="mb-2"></b-progress>
                                                <h4>NIC-2:
                                                    <small class="text-muted">3/10GB</small>
                                                </h4>
                                                <b-progress height="20px" :value="constellation.nic2" show-progress
                                                            class="mb-2"></b-progress>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-tab>

                        <!--Devices Tab-->
                        <b-tab class="tab-scroll" title="Your Devices">
                            <v-client-table @row-click="rowClicked" :columns="columns" :data="devices" :options="options" :theme="theme" class="dataTable">
                                <div slot="info" slot-scope="props">
                                    <div class="row" style="display: flex; justify-content: flex-end; margin-right: auto">
                                        <div class="col-1">
                                            <router-link class="non-link" :to="{ name: 'Forensic Monitoring', params: { deviceId: props.row._id, constellationUrl: props.row.constellationurl }  }">
                                                <fa-icon :icon="['fas', 'chart-column']" style="margin-bottom: 0"/>
                                            </router-link>
                                        </div>
                                        <div class="col-1">
                                            <fa-icon :icon="['fas', 'info']" @click="showDevicesInfoModal(props.row)"/>
                                        </div>
                                    </div>
                                </div>
                                <div slot="h__info">
                                    <span style="float:right">Forensics/Info</span>
                                </div>
                                <div style="text-align:center" slot="h__overview">
                                    <span>Overview</span>
                                </div>
                                <div style="text-align:center" slot="overview" slot-scope="props">
                                    <router-link class="non-link" :to="{ name: 'Device Overview', params: { deviceId: props.row._id, constellationId: props.row.constellationId }  }">
                                        <i class="icon-map icons font-xl"></i>
                                    </router-link>
                                </div>
                                <div slot="status" slot-scope="props">
                                    <div v-if="props.row.status === 'active'">
                                        <b-badge :variant="'success'">{{props.row.status}}</b-badge>
                                    </div>
                                    <div v-else-if="props.row.status === 'inactive'">
                                        <b-badge :variant="'danger'">{{props.row.status}}</b-badge>
                                    </div>
                                </div>
                            </v-client-table>
                        </b-tab>

                    </b-tabs>
                </b-card>
            </b-col>

        </b-row>

    </b-card>
</template>

<script>
  import Vue from 'vue'
  import fcr from '../../../services/fcr.js'
  import iss from '../../../services/iss.js'
  import axios from 'axios'
  import VueNestable from 'vue-nestable'
  Vue.use(VueNestable)
  import {ClientTable} from 'vue-tables-2'
  Vue.use(ClientTable)
  import constellationStore from "@/store/constellationStore";
  import navbarStore from "@/store/navbarStore";

export default {
    data: () => {
        return {
            refresh: false,
            local: {},
            groups:[],
            users:[],
            navOptions: [],
            constellationList: [],
            tooltipTitle:"",
            tooltipRender:true,
            target: '',
            devices: [],
            devicesInfoModal: false,
            currentDevice: {},

            //More Info Modal
            deviceFields: [
                {name: 'ID', field: '_id'},
                {name: 'Constellation*', field: 'constellation'},
                {name: 'Name', field: 'friendlyName'},
                {name: 'Asset Tag', field: 'assettag'},
                {name: 'IP Address*', field: 'ipAddress'},
                {name: 'Subnet', field: 'subnet'},
                {name: 'Gateway', field: 'gateway'},
                {name: 'DNS #1', field: 'dns1'},
                {name: 'DNS #2', field: 'dns2'},
                {name: 'Latitude', field: 'latitude'},
                {name: 'Longitude', field: 'longitude'},
                {name: 'Make', field: 'make'},
                {name: 'Model', field: 'model'},
                {name: 'MAC Address', field: 'macaddress'},
                {name: 'Serial Number', field: 'serialnumber'},
                {name: 'Device Type', field: 'devicetype'},
                {name: 'Username', field: 'username'},
                {name: 'Password', field: 'password'},
                {name: 'Status', field: 'status'},
            ],
            elasticBandFields:[
                {name:'Username', field:'user'},
                {name:'Streams', field:'streams'},
            ],
            streamFields:[
                {name:'ID', field:'id'},
                {name:'Protocol', field:'protocol'},
                {name:'URL', field:'url'},
                {name:'Bandwidth', field:'bandwidth'},
                {name:'Status', field:'status'},
                {name:'Active', field:'active'},
            ],

            //Devices Table Variables
            columns: ['constellation', 'friendlyName', 'ipAddress', 'devicetype', 'status', 'overview', 'info'],
            options: {
                orderBy: {column: 'friendlyName', ascending: false},
                headings: {
                    constellation: 'Constellation',
                    friendlyName: 'Name',
                    ipAddress: 'IP',
                    devicetype: 'Device Type',
                    status: 'Status',
                    info: 'Info/Edit',
                },
                sortable: ['constellation', 'friendlyName', 'ipAddress', 'devicetype', 'status'],
                filterable: ['constellation', 'friendlyName', 'ipAddress', 'devicetype', 'status'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 15,
                skin: 'table table-striped table-hover',
            },
            useVuex: false,
            theme: 'bootstrap4',
            template: 'default',
        }

    },
    /*beforeRouteLeave (to, from, next) {
      next();
      location.reload();
    },*/

    beforeCreate() {
      iss.getUsersInGroup().then(response => {
        this.users = response.data;
      }).catch(error => {
        this.$mToast({
          title: error.response.status + ' Error',
          text: "Users couldn't be retrieved: " + error.response.statusText,
          style: 'error'
        });
      });

      navbarStore.dispatch('getNavInfo').then(response => {
          response.forEach(item => {
              item.id = item._id;
          });
          this.navOptions = response;
      })



        constellationStore.dispatch('getConstellations').then(response => {
        this.constellationList = response;

        iss.getAllGroups().then(response => {
          response.data.forEach(function(group){
            group.constellationnames=[];
            group.constellations.forEach(function(constellationId){
              this.constellationList.forEach(function(constellation){
                if(constellationId === constellation._id){
                  group.constellationnames.sort();
                  group.constellationnames.push(constellation.constellationname);
                }
              }.bind(this))
            }.bind(this))
            //once we've added all constellation names, push group
            this.groups.push(group);
          }.bind(this))
        }).catch(error => {
          this.$mToast({
            title: error.response.status + ' Error',
            text: "Groups couldn't be retrieved: " + error.response.statusText,
            style: 'error'
          });
        });

        this.constellationList.forEach(function (constellation) {
          constellation.numInactiveDevices=0;
          constellation.numActiveDevices=0;
          axios({
            method: 'get',
            url: "https://" + constellation.url + ":" + constellation.port + '/device/list',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'JWT ' + localStorage.oldToken
            }
          }).then(response => {
            if (Array.isArray(response.data)) {
              response.data.forEach(function (eachDevice) {
                eachDevice.constellationId = constellation._id
                eachDevice.constellation = constellation.constellationname;
                eachDevice.constellationurl = (constellation.url + ":" + constellation.port)
                if(!eachDevice.status || eachDevice.status==="inactive"){
                  constellation.numInactiveDevices++;
                }
                else{
                  constellation.numActiveDevices++;
                }
              }.bind(this));
              this.devices = (this.devices).concat(response.data);
            }
          });
        }.bind(this));
      }).catch(error => {
        this.$mToast({
          title: error.response.status + ' Error',
          text: "Constellations couldn't be retrieved: " + error.response.statusText,
          style: 'error'
        });
      })
    },

    mounted(){
      this.local=localStorage;
    },

    methods: {
      updateNav(){
        let ids = this.navOptions.map(a => a._id);
        fcr.updateNav(localStorage.id, ids).then(response => {
          this.$emit('navUpdate', response.data.navbars);
        });
      },
      showDevicesInfoModal(device) {
        this.currentDevice = device;
        this.devicesInfoModal = true;
      },
      getColor(status) {
        return status === false ? 'danger'
          : status === true ? 'success': 'warning'
      },
      getBadge (status) {
        return !status.dockerInfo ? 'danger'
          : (status.dockerInfo||{}).Status === 'running' ? 'success' : 'warning'
      },
      rowClicked(item) {
        //ctrl click to make tooltip appear with id
        if(item.event.ctrlKey){
          this.tooltipRender=false;
          this.tooltipTitle=item.row._id;
          item.event.target.id=item.row._id;
          this.target=item.row._id;
          //restart tooltip so it can map to its new position
          Vue.nextTick().then(() => {
            this.tooltipRender=true;
            Vue.nextTick().then(() => {
              this.$refs.tooltip.$emit('open')
              setTimeout(function(){
                //make the tooltip disapear automatically
                this.$refs.tooltip.$emit('close');
              }.bind(this),4000)
            });
          });
        }
      },
    },
  }
</script>

<style>
  .nestable {
    position: relative;
  }
  .nestable .nestable-list {
    margin: 0;
    padding: 0 0 0 40px;
    list-style-type: none;
  }
  .nestable > .nestable-list {
    padding: 0;
  }
  .nestable-item,
  .nestable-item-copy {
    margin: 10px 0 0;
  }
  .nestable-item:first-child,
  .nestable-item-copy:first-child {
    margin-top: 0;
  }
  .nestable-item .nestable-list,
  .nestable-item-copy .nestable-list {
    margin-top: 10px;
  }
  .nestable-item {
    position: relative;
  }
  .nestable-item.is-dragging .nestable-list {
    pointer-events: none;
  }
  .nestable-item.is-dragging * {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .nestable-item.is-dragging:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(106, 127, 233, 0.274);
    border: 1px dashed rgb(73, 100, 241);
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  .nestable-drag-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    pointer-events: none;
  }
  .nestable-drag-layer > .nestable-list {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    background-color: rgba(106, 127, 233, 0.274);
  }
  .nestable [draggable="true"] {
    cursor: move;
  }
  .nestable-handle {
    display: inline;
  }
</style>
